import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Home } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { withRouter } from 'react-router-dom';
import { CuponDataController } from '../controller/CuponDataController';
import { Verify } from '../../utils/Verify';
import { ScannerController } from '../../Scanner/controller/ScannerController';
class CuponDataComponent extends React.Component {
	constructor(props) {
		super(props);
		this.cuponDataController = new CuponDataController();
		this.verify = new Verify();
		this.scannerController = new ScannerController();
		this.state = {
			data: {
				qr: {
					clientVO: {
						personVO: {
							name: ''
						}
					},
					lifes: 0
				}
			},
			isValid: 'validez',
			desc: '',
			status: 'válido/inválido',
			expiration: 'expiración',
			description: 'descripción',
			discount: '% de descuento',
			lifes: 'vidas',
			client: 'cliente'
		};
	}

	componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		this.loadData();
	}

	async loadData() {
		const body = await this.cuponDataController.init();

		if (!body || body === null) {
			this.sendError('¡Ocurrió un error al escanear el cupón!');
		} else if (body.id === -1) {
			this.sendError(
				'Cupón invalido. Escanee un cupón que pertenezca a este sistema.'
			);
		} else {
			this.setState({ data: body });
			this.isValid();
		}
	}

	sendError(message) {
		sessionStorage.setItem('visited', 0);
		setTimeout(() => {
			this.props.history.push('/scanner');
		}, 3500);
		Utils.swalError(message);
	}

	async auth() {
		const res = await this.cuponDataController.autorizar();
		if (res === false) {
			this.props.history.push('/login');
		} else {
			Utils.swalSuccess('Se autorizó con éxito!');
			this.verify.MenuTypeUser(this.props.history);
			window.location.reload(true);
		}
	}

	back = (_) => {
		window.history.back();
		window.location.reload();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
		window.location.reload();
	};

	go2client = (_) => {
		this.props.history.push('/clientAdd');
	};

	formatDate = (date) => {
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (month < 10) {
			if (day < 10) return `0${day}-0${month}-${year}`;
			else return `${day}-0${month}-${year}`;
		} else {
			if (day < 10) return `0${day}-${month}-${year}`;
			else return `${day}-${month}-${year}`;
		}
	};

	render() {
		return (
			<div className='container'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div
							className={
								this.state.status === 'VÁLIDO'
									? 'container-sm p-3 my-3 bg-success text-white'
									: 'container-sm p-3 my-3 bg-danger text-white'
							}
							id='cont'
						>
							<h1>Escaneo de Cupones</h1>
							<p id='isValid' className='text-white'>
								{this.state.isValid}
							</p>
							<p id='descr' className='text-white'>
								{this.state.desc}
							</p>
						</div>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<div id='mess'></div>
							</div>
							<div className='card-body'>
								<div className='d-flex justify-content-center'>
									<div
										className={
											this.state.status === 'VÁLIDO'
												? 'p-2 bg-primary'
												: 'p-2 bg-danger'
										}
										style={this.mediumSize}
										id='status'
									>
										{this.state.status}
									</div>
								</div>
								<br />
								<div className='d-flex justify-content-center'>
									<div
										className='p-2 bg-secondary'
										style={this.mediumSize}
										id='expiration'
									>
										{this.state.expiration}
									</div>
								</div>
								<br />
								<div className='d-flex justify-content-center'>
									<div
										className='p-2 bg-secondary'
										style={this.mediumSize}
										id='description'
									>
										{this.state.description}
									</div>
								</div>
								<br />
								<div className='d-flex justify-content-center'>
									<div
										className='p-2 bg-secondary'
										style={this.smallSize}
										id='discount'
									>
										{this.state.discount}
									</div>
									<div
										className='p-2 bg-secondary'
										style={this.smallSize}
										id='lifes'
									>
										{this.state.lifes}
									</div>
								</div>
								<br />
								<div className='d-flex justify-content-center'>
									<div className='p-2 bg-secondary' style={this.mediumSize}>
										{this.state.data.client_id
											? this.state.data.client_id.personVO.name +
											  ' ' +
											  this.state.data.client_id.personVO.surname
											: 'Sin cliente'}
									</div>
								</div>
								<br />
								<div className='row justify-content-center'>
									{this.renderButton()}
								</div>
							</div>
							<br />
							<div className='row justify-content-center text-white'>
								{this.state.status === 'VÁLIDO' && this.state.data.client_id ? (
									<button
										type='button'
										className='btn btn-success btn-md btn-block p-2'
										onClick={() => this.auth()}
										style={{ maxWidth: '300px' }}
										id='btn'
									>
										AUTORIZAR
									</button>
								) : (
									<button type='button' className='invisible' id='btn'>
										AUTORIZAR
									</button>
								)}
							</div>
							<br />
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos id='btnbackscanner' onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home id='btnhomescanner' onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	isValid() {
		const fechaActual = new Date();
		fechaActual.setHours(0, 0, 0, 0);
		const fechaBD = new Date(this.state.data.coupon_id.expirationDate);
		fechaBD.setHours(0, 0, 0, 0);
		fechaBD.setDate(fechaBD.getDate() + 1);
		this.setState({
			data: {
				...this.state.data,
				coupon_id: {
					...this.state.data.coupon_id,
					expirationDate: fechaBD
				}
			}
		});
		if (fechaBD >= fechaActual) {
			if (this.state.data.lifes > 0) {
				this.setState({ isValid: 'El cupón es válido' });
				this.setState({ status: 'VÁLIDO' });
			} else {
				this.setState({ status: 'INVÁLIDO' });
				this.setState({
					isValid: 'El cupón es inválido, ya ha sido utilizado.'
				});
				this.setState({ desc: 'No quedan descuentos' });
			}
		} else {
			this.setState({ status: 'INVÁLIDO' });
			this.setState({ isValid: 'El cupón es inválido' });
			this.setState({ desc: 'Cupón expirado' });
		}

		const expiration = this.formatDate(
			new Date(this.state.data.coupon_id.expirationDate)
		);
		this.setState({ expiration: expiration });
		const description = this.state.data.coupon_id.description;
		this.setState({ description: description });
		const discount = this.state.data.active_discount + ' %desc';
		this.setState({ discount: discount });
		const lifes = 'Descuentos Restantes ' + this.state.data.lifes;
		this.setState({ lifes: lifes });

		if (this.state.data.coupon_id.status !== 1) {
			this.setState({
				status: 'INVÁLIDO',
				isValid: 'Este cupón se encuentra deshabilitado.',
				desc: 'Contacte a un administrador'
			});
		}
	}

	renderButton() {
		if (this.state.status === 'VÁLIDO') {
			if (this.state.data.clientVO) {
				return (
					<button
						className='invisible'
						type='button'
						style={{ maxWidth: '300px' }}
					>
						Asignar Cliente
					</button>
				);
			} else {
				return (
					<button
						className='btn btn-primary btn-md btn-block'
						onClick={this.go2client}
						type='button'
						style={{ maxWidth: '300px' }}
					>
						Asignar Cliente y Autorizar
					</button>
				);
			}
		} else {
			return (
				<button
					className='invisible'
					type='button'
					style={{ maxWidth: '300px' }}
				>
					Asignar Cliente
				</button>
			);
		}
	}

	smallSize = { width: '150px', textAlign: 'center' };
	mediumSize = { width: '300px', textAlign: 'center' };
}

export default withRouter(CuponDataComponent);
