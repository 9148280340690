import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Verify } from '../../utils/Verify';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookIcon from '@material-ui/icons/Facebook';
import SocialNetworkComponent from './SocialNetworkComponent';
import { BusinessController } from '../controller/BusinessController';
import { QrFormController } from '../../QrGrid/Controller/QrFormController';
import { Utils } from '../../resources/Utils';

import 'react-multi-carousel/lib/styles.css';
import './StyleSocial.css';
import '../../resources/css/StyleHome.css';
import '../../resources/css/Modals.css';
import '../../resources/css/General.css';

import Maps from '../../resources/MapsComponent';

class BussinesComponent extends React.Component {
	constructor(props) {
		super(props);
		this.verify = new Verify();
		this.businessController = new BusinessController();
		this.qrFormController = new QrFormController();
		this.state = {
			bussinesVO: {
				view: 0,
				id: '',
				name: '',
				slogan: '',
				description: '',
				phone: '',
				contacEmail: '',
				googlemaps: '',
				instagramLink: '',
				facebookLink: '',
				twitterLink: '',
				mainSociamedia: '',
				catalogImgVO: '',
				Image1: '',
				Image3: '',
				status: '1',
				coordsMap: []
			},
			CPN: [
				{
					platform: 'React',
					code: '#cuponealo.com.mx#',
					idBusiness: 1,
					border: 'si',
					centerColor: [0, 0, 0],
					cornerColor: [0, 0, 0],
					backgroundColor: [0, 0, 0],
					gradient: 'radial',
					pattern: 'circulo',
					corner: 1,
					eye: 1,

					description: 'Cupon',
					lifes: 2,
					expirationDate: '2022-07-10',
					isIncrement: 1,
					discount: 5,
					titleFont: 'HebertSans',
					textFont: 'HebertSans',
					titleColor: [0, 0, 0],
					textColor: [0, 0, 0],

					createdAt: 0,
					createdBy: 1,
					id: 1,
					idProduct: null,
					modifiedAt: 1656344761000,
					modifiedBy: 1,
					price: 250,
					priceDiscount: 250,
					status: 1,
					urlImg: null
				}
			],
			page: 0,
			total: 0
		};
	}

	componentDidMount() {
		const info = JSON.parse(sessionStorage.getItem('info'));
		this.setState({ bussinesVO: info });
		setTimeout(() => {
			document.getElementById('imagePreview').src =
				this.state.bussinesVO.logoLink.replace('banner', 'profile');
			this.getCoordsData();
		}, 300);
		this.couponsToQr();
	}

	componentWillUnmount() {
		sessionStorage.removeItem('attemptMedia');
	}

	componentDidCatch() {
		this.setState({
			bussinesVO: {
				...this.state.bussinesVO,
				mainSociamedia: ''
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.bussinesVO.mainSociamedia !==
			prevState.bussinesVO.mainSociamedia
		) {
			document.getElementById('imagePreview').src =
				this.state.bussinesVO.catalogImgVO.description.replace(
					'banner',
					'profile'
				);
			this.getCoordsData();
		}
	}

	couponsToQr = async () => {
		const coupon = await this.businessController.getInfoCupon(this.state.page);
		const aux = [];
		if (coupon.data[0] != null && coupon.data !== undefined) {
			this.setState({
				CPN: { ...this.state.CPN, idBusiness: coupon.data[0].idBusiness.id }
			});
			this.setState({ total: coupon.total });

			const qr = await this.qrFormController.findQrBusiness(
				this.state.CPN.idBusiness
			);

			for (let index = 0; index < coupon.total; index++) {
				if (coupon.data[index] && qr.data) {
					const info = Object.assign({}, coupon.data[index], qr.data);
					if (coupon.data[index].qr && coupon.data[index].qr.qr_address) {
						info.code = coupon.data[index].qr.qr_address;
					}
					const urlImg = await this.businessController.getPreview(info);
					aux.push(Object.assign({}, coupon.data[index], qr.data, { urlImg }));
				}
			}
			this.setState({ CPN: aux });
		}
	};

	funcModal = (m, opc, url) => {
		const mP = document.getElementById('modalPanel');
		const mCA = document.getElementById('modalContent');
		const img = document.getElementById('imgRender');
		const download = document.getElementById('download');

		if (opc === 'cerrar') {
			mP.setAttribute('style', 'display:none;');
			mCA.setAttribute('style', 'display:none;');
		} else {
			img.setAttribute('src', URL.createObjectURL(url));
			download.setAttribute('href', URL.createObjectURL(url));
			if (m === 'aviso') {
				mP.setAttribute('style', 'display:block;');
				mCA.setAttribute('style', 'display:block;');
			}
		}

		window.scroll({
			top: 0,
			behavior: 'smooth'
		});
	};

	renderCupon = () => {
		const info = this.state.CPN;
		return info.map((cupon, index) => (
			<div
				key={index}
				onClick={() => this.funcModal('aviso', 'abrir', cupon.urlImg)}
				className='p-1 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 text-white cpn'
			>
				<img
					id='qrPanel'
					src={URL.createObjectURL(cupon.urlImg)}
					className='img-thumbnail'
					width='90%'
					alt='Vista previa'
					style={{ left: '5%' }}
				/>
				<div className='smallText'>
					{cupon.discount}% {cupon.description}
				</div>
			</div>
		));
	};

	pageCupons(page) {
		this.setState(
			{
				CPN: [
					{
						platform: 'React',
						code: '#cuponealo.com.mx#',
						idBusiness: 1,
						border: 'si',
						centerColor: [0, 0, 0],
						cornerColor: [0, 0, 0],
						backgroundColor: [0, 0, 0],
						gradient: 'radial',
						pattern: 'circulo',
						corner: 1,
						eye: 1,

						description: 'Cupon',
						lifes: 2,
						expirationDate: '2022-07-10',
						isIncrement: 1,
						discount: 5,
						titleFont: 'HebertSans',
						textFont: 'HebertSans',
						titleColor: [0, 0, 0],
						textColor: [0, 0, 0],

						createdAt: 0,
						createdBy: 1,
						id: 1,
						idProduct: null,
						modifiedAt: 1656344761000,
						modifiedBy: 1,
						price: 250,
						priceDiscount: 250,
						status: 1,
						urlImg: null
					}
				],
				page: page
			},
			() => {
				this.couponsToQr();
			}
		);
	}

	async previewImage(url) {
		if (url.type !== 'image/png') {
			Utils.swalError(
				'Ah ocurrido un error al generar el QR! Asegúrete de ingresar los datos correctamente.'
			);
			const element = document.getElementById('qrPanel');
			element.parentNode.removeChild(element);
		} else {
			document.getElementById('qrPanel').src = URL.createObjectURL(url);
		}
	}

	getCoordsData = async () => {
		const resp = await this.businessController.findCoords(
			this.state.bussinesVO.id
		);
		this.setState({ coordsMap: resp.data });

		let auxC = [];
		const coords = [];
		this.state.coordsMap.forEach((c) => {
			auxC = [
				{
					id: c.id,
					idBusiness: c.idBusiness,
					position: {
						lat: c.latitude,
						lng: c.longitude
					},
					key: c.id,
					animation: 2,
					name: this.state.bussinesVO.name
				}
			];

			coords.push.apply(coords, auxC);
			auxC = [];
		});

		this.setState({ coordsMap: coords });

		sessionStorage.setItem('coordsBus', JSON.stringify(coords));
	};

	back = (_) => {
		window.history.back();
	};

	renderContent() {
		return (
			<div>
				<div className='container'>
					<div className='row justify-content-center justify-content-center'>
						<div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 mt-4'>
							<div className='row'>
								<div className='col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12'>
									<img
										id='imagePreview'
										src=''
										className='img-thumbnail'
										alt='Vista previa'
									/>
								</div>
								<div className='col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12'>
									<br></br>
									<h4>{this.state.bussinesVO.name}</h4>
									<h5>{this.state.bussinesVO.slogan}</h5>
									<h6>{this.state.bussinesVO.phone}</h6>
									<h6>{this.state.bussinesVO.contacEmail}</h6>

									<ul className='list-unstyled text-muted mt-4'>
										{this.state.bussinesVO.facebookLink !== undefined &&
										this.state.bussinesVO.facebookLink !== '' ? (
											<li>
												<a href={this.state.bussinesVO.facebookLink}>
													<FacebookIcon color='enable' />
													&nbsp;&nbsp;Facebook
												</a>
											</li>
										) : null}

										{this.state.bussinesVO.instagramLink !== undefined &&
										this.state.bussinesVO.instagramLink !== '' ? (
											<li>
												<a
													href={
														'https://www.tiktok.com/@' +
														this.state.bussinesVO.instagramLink
													}
												>
													<svg
														fill='#0085FF'
														xmlns='http://www.w3.org/2000/svg'
														viewBox='0 0 50 50'
														width='23px'
													>
														<path d='M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z' />
													</svg>
													&nbsp;&nbsp;TikTok
												</a>
											</li>
										) : null}

										{this.state.bussinesVO.twitterLink !== undefined &&
										this.state.bussinesVO.twitterLink !== '' ? (
											<li>
												<a
													href={
														'https://twitter.com/' +
														this.state.bussinesVO.twitterLink
													}
												>
													<FontAwesomeIcon icon={faXTwitter} />
													&nbsp;&nbsp;Twitter
												</a>
											</li>
										) : null}
									</ul>
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 mt-4'>
							<h6>{this.state.bussinesVO.description}</h6>

							<br></br>

							<div className='col'>
								<div className='row align-items-start justify-content-start ml-2'>
									{this.state.CPN[0].urlImg != null &&
									this.state.CPN[0].urlImg !== undefined ? (
										this.state.CPN[0].id === -1 ? (
											<span />
										) : (
											this.renderCupon()
										)
									) : this.state.total > 0 ? (
										<div>
											Están cargando los cupones... Espere un momento &nbsp;
											<div className='spinner-border spinner-border-sm text-danger'></div>
										</div>
									) : (
										<div>
											¡Lo sentimos! El negocio aún no cuenta con cupones
											activos.
										</div>
									)}
								</div>

								<div className=' row my-2 mb-4 d-flex justify-content-left align-items-center'>
									{this.state.total > 5 ? (
										<div className='d-flex flex-row'>
											<div className='d-flex flex-column'>
												<div
													className={
														this.state.page > 0
															? 'button btn btn-outline-danger'
															: 'button btn btn-outline-danger disabled'
													}
													onClick={
														this.state.page > 0
															? () => this.pageCupons(this.state.page - 1)
															: null
													}
												>
													Anterior
												</div>
											</div>
											<div
												className='d-flex flex-column'
												style={{ paddingLeft: '10px' }}
											>
												<div
													className={
														(this.state.page + 1) * 6 < this.state.total
															? 'button btn btn-outline-danger'
															: 'button btn btn-outline-danger disabled'
													}
													onClick={
														(this.state.page + 1) * 6 < this.state.total
															? () => this.pageCupons(this.state.page + 1)
															: null
													}
												>
													Siguiente
												</div>
											</div>
										</div>
									) : (
										<span></span>
									)}
								</div>
							</div>

							<div className='row justify-content-between'>
								<div className='col-11 row mapCaja mt-4 ml-3'>
									{this.state.coordsMap ? (
										<Maps
											data={this.state.coordsMap}
											actionAct={'Show'}
											mode={'Buss'}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						<div className='col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-4 mt-5 marrig-1'>
							<SocialNetworkComponent
								props={{
									option: this.state.bussinesVO.mainSociamedia,
									user: {
										facebook: this.state.bussinesVO.facebookLink,
										tikTok: this.state.bussinesVO.instagramLink,
										twitter: this.state.bussinesVO.twitterLink
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		window.scroll({ top: 0 });

		return (
			<div className='container py-md-4 py-lg-4 py-sm-2 py-xl-4 col-xl-10 col-lg-10 col-md-10 col-sm-10'>
				<div className='row justify-content-center'>
					<div className='col-sd-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-body'>
								<div className='container-fluid'>{this.renderContent()}</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-10'></div>
									</div>
								</div>
							</div>

							<div className='modalCup cuponModal' id='modalPanel'>
								<div
									className='modal-content cuponModalContent'
									id='modalContent'
								>
									<div className='modal-header cuponModalHeader'>
										<button
											className='btn btn-link botonCerrar'
											onClick={() => this.funcModal('a', 'cerrar')}
										>
											<div className='close'>&times;</div>
										</button>
									</div>

									<div className='modal-body cuponModalBody'>
										<img id='imgRender'></img>
									</div>

									<div className='modal-footer cuponModalFooter'>
										<a
											id='download'
											className='btn btn-secondary'
											download={'Cupon'}
										>
											Descargar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BussinesComponent);
