import React from 'react';
import { withRouter } from 'react-router-dom';
import { Verify } from '../../utils/Verify';
import '../../resources/css/General.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faCakeCandles } from '@fortawesome/free-solid-svg-icons/faCakeCandles';
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { faUserGear } from '@fortawesome/free-solid-svg-icons/faUserGear';
import { faBuildingUser } from '@fortawesome/free-solid-svg-icons/faBuildingUser';
import { faClipboardUser } from '@fortawesome/free-solid-svg-icons/faClipboardUser';
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket';

class MenuSuperAdminComponent extends React.Component {
	constructor() {
		super();
		this.verify = new Verify();
		this.state = {
			data: []
		};
	}

	componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
	}

	go2cuponForm = (event) => {
		event.preventDefault();
		this.props.history.push('/cuponCreation');
	};

	go2clientGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/clientGrid');
	};

	logout = (event) => {
		event.preventDefault();
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
		sessionStorage.removeItem('role');
		this.props.history.push('/login');
		window.location.reload(false);
	};

	go2scanner = (event) => {
		event.preventDefault();
		sessionStorage.setItem('visited', 0);
		this.props.history.push('/scanner');
	};

	go2birthday = (event) => {
		event.preventDefault();
		this.props.history.push('/birthdayDudes');
	};

	go2cuponGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/cuponGrid');
	};

	go2bussinesAdd = (event) => {
		event.preventDefault();
		this.props.history.push('/bussinesAdd');
	};

	go2businessGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/businessGrid');
	};

	go2QrGrid = (event) => {
		event.preventDefault();
		this.props.history.push('/qrGrid');
	};

	button(textBtn, classNameBtn, onClickBtn, level) {
		if (this.verify.securityVerify(level)) {
			return (
				<button type='button' className={classNameBtn} onClick={onClickBtn}>
					{textBtn}
				</button>
			);
		} else {
			return <></>;
		}
	}

	go2EmployeesGrid = (key) => {
		sessionStorage.setItem('typeUser', key);
		this.props.history.push('/usersGrid');
	};

	render() {
		return (
			<div className='container h-100 py-5'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div className='card shadow bg-light text-dark'>
							<div className='card-header'>
								<div>
									<a href='/index' className='btn btn-outline-danger ml-2'>
										{' '}
										Inicio{' '}
									</a>
								</div>
								<h4 className='text-center' id='menutitle'>
									Menú de Opciones{' '}
								</h4>
								<div>
									<button
										type='button'
										className='btn btn-outline-danger ml-2'
										onClick={this.logout}
									>
										{' '}
										Cerrar Sesión{' '}
									</button>
								</div>
							</div>
						</div>
						<div className='card shadow bg-dark text-white align-items-center'>
							<div className='card-body'>
								<div
									className='container-sm align-items-center'
									id='ContainerD'
								>
									<div className='ContainerA'>
										<div
											className='btnN btn-red btn-md'
											onClick={this.go2bussinesAdd}
										>
											<div>
												<FontAwesomeIcon icon={faBriefcase} className='icon' />
											</div>
											Crear Negocios
										</div>
										<div
											className='btnN btn-gold btn-md '
											onClick={this.go2businessGrid}
										>
											<div>
												<FontAwesomeIcon
													icon={faBuildingUser}
													className='icon'
												/>
											</div>
											Administrar negocios
										</div>
										<div
											className='btnN btn-yellow btn-md'
											onClick={this.go2cuponForm}
										>
											<div>
												<FontAwesomeIcon icon={faBarcode} className='icon' />
											</div>
											Generar Cupones
										</div>
										<div
											className='btnN btn-lemon btn-md'
											onClick={this.go2scanner}
										>
											<div>
												<FontAwesomeIcon icon={faExpand} className='icon' />
											</div>
											Escanear Cupón
										</div>
										<div
											className='btnN btn-green btn-md'
											onClick={this.go2QrGrid}
										>
											<div>
												<FontAwesomeIcon icon={faQrcode} className='icon' />
											</div>
											Editar Qr
										</div>

										<div
											className='btnN btn-redora btn-md'
											onClick={this.go2birthday}
										>
											<div>
												<FontAwesomeIcon
													icon={faCakeCandles}
													className='icon'
												/>
											</div>
											Cumpleañeros
										</div>
										<div
											className='btnN btn-hotPink btn-md '
											onClick={() => this.go2EmployeesGrid('employee')}
										>
											<div>
												<FontAwesomeIcon icon={faUserTie} className='icon' />
											</div>
											Administrar Empleados
										</div>
										<div
											className='btnN btn-violet btn-md'
											onClick={this.go2cuponGrid}
										>
											<div>
												<FontAwesomeIcon icon={faTicket} className='icon' />
											</div>
											Administrar Cupones
										</div>
										<div
											className='btnN btn-seaGreen btn-md'
											onClick={() => this.go2EmployeesGrid('admin')}
										>
											<div>
												<FontAwesomeIcon
													icon={faClipboardUser}
													className='icon'
												/>
											</div>
											Gestionar Administradores
										</div>
										<div
											className='btnN btn-cyan btn-md'
											onClick={this.go2clientGrid}
										>
											<div>
												<FontAwesomeIcon icon={faUserGear} className='icon' />
											</div>
											Administrar Clientes
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(MenuSuperAdminComponent);
