import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginComponent from './Login/component/LoginComponent';
import ForgottenComponent from './Login/component/ForgottenComponent';
import CuponFormComponent from './CuponForm/component/CuponFormComponent';
import ClientFormComponent from './ClientForm/component/ClientFormComponent';
import ClientAddComponent from './ClientForm/component/ClientAddComponent';
import ClientSelectComponent from './ClientForm/component/ClientSelectComponent';
import ClientGridComponent from './ClientGrid/component/ClientGridComponent';
import CuponDataComponent from './Scan/component/CuponDataComponent';
import ScannerComponent from './Scanner/component/ScannerComponent';
import BirthdayComponent from './Birthday/component/BirthdayComponent';
import CuponGridComponent from './CuponGrid/component/CuponGridComponent';
import CuponEditFormComponent from './CuponForm/component/CuponEditFormComponent';
import HomePageComponent from './Index/component/HomePage';
import BusinessComponent from './Business/component/BusinessComponent';
import BussinesFormComponent from './BusinessForm/component/BussinesFormComponent';
import BusinessGridComponent from './BusinessGrid/component/BusinessGridComponent';
import MenuSuperAdminComponent from './Menu/component/MenuSuperAdminComponent';
import QrFormComponent from './QrGrid/Component/QrFormComponent';
import EmployeeGridComponent from './EmployeeGrid/component/EmployeeGridComponent';
import EmployeeEditFormComponent from './EmployeeGrid/component/EmployeeEditFormComponent';
import NoticeComponent from './Privacy/NoticeComponent';

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			view: 0
		};
	}

	render() {
		return (
			<div className='App'>
				<div className='text-white'>
					<Router>
						<div className='mt-3'>
							<Switch>
								<Route path='/forgotten' component={ForgottenComponent} />
								<Route path='/cuponCreation' component={CuponFormComponent} />
								<Route path='/cuponData' component={CuponDataComponent} />
								<Route path='/clientForm' component={ClientFormComponent} />
								<Route path='/clientAdd' component={ClientAddComponent} />
								<Route path='/clientSelect' component={ClientSelectComponent} />
								<Route path='/clientGrid' component={ClientGridComponent} />
								<Route path='/cuponGrid' component={CuponGridComponent} />
								<Route path='/cuponEdit' component={CuponEditFormComponent} />
								<Route path='/usersGrid' component={EmployeeGridComponent} />
								<Route path='/userForm' component={EmployeeEditFormComponent} />
								<Route
									path='/superAdminMenu'
									component={MenuSuperAdminComponent}
								/>
								<Route path='/birthdayDudes' component={BirthdayComponent} />
								<Route path='/scanner' component={ScannerComponent} />
								<Route path='/login' component={LoginComponent} />
								<Route path='/bussinesAdd' component={BussinesFormComponent} />
								<Route path='/businessGrid' component={BusinessGridComponent} />
								<Route path='/business' component={BusinessComponent} />
								<Route path='/qrGrid' component={QrFormComponent} />
								<Route path='/Privacy' component={NoticeComponent} />
								<Route path='/' component={HomePageComponent} />
							</Switch>
						</div>
					</Router>
				</div>
			</div>
		);
	}
}
